@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Para aplicar estilos al body cuando el modal está visible */
body:has(div[id="root"][aria-hidden="true"]) {
  overflow-y: hidden !important;
}

input[type="file"]::file-selector-button {
  border: none;
  height: 100%;
  color: #fff;
  cursor: pointer;
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  background: #00559e;
}

input[type="file"]::file-selector-button:hover {
  background: #00559e;
}
